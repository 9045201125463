import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { feedAnimations } from "app/animations";
import { EvaluationService } from "app/services/evaluation/evaluation.service";
import { ProjectService } from "app/services/project/project.service";
import { UserService } from "app/services/user/user.service";
import { Evaluation, Partial } from "app/shared/models/vuelta-d.interface";
import { environment } from "environments/environment";
import moment from "moment";
import { SendPollComponent } from "../send-poll-dialog/send-poll-dialog.component";

@Component({
	selector: "app-form",
	templateUrl: "./form.component.html",
	styleUrls: ["./form.component.scss"],
	encapsulation: ViewEncapsulation.None,
	animations: feedAnimations,
})
export class FormComponent implements OnInit {
	params;
	user;
	editMode;

	periodSelected;
	evaluationForm;
	selectedBlock;
	evaluation;

	projectId = 9;
	sectionsRequired = [];
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private sanitizer: DomSanitizer,
		private dialog: MatDialog,
		private userSvc: UserService,
		private projectSvc: ProjectService,
		private evaluationSvc: EvaluationService,
		private snackBar: MatSnackBar,
		private translate: TranslateService
	) {}

	ngOnInit(): void {
		// Check page access
		this.projectSvc.getProjectConfig(false).subscribe((project) => {
			this.projectId = project.id;
			if (!project.enabled_mod_polls) {
				this.router.navigate(["/inicio"]);
			}
		});
		this.route.queryParams.subscribe((params) => {
			this.params = params;
			// Load period
			this.evaluationSvc.getEvaluationPeriodById(this.params.id).subscribe((data) => {
				console.log("Period", data);
				this.periodSelected = data;
				if (this.periodSelected.headerImg) {
					this.periodSelected.headerImg = environment.urlDomain + "/rest/image/" + this.periodSelected.headerImg;
				}
				switch (localStorage.getItem("currentLang")) {
					case "it":
						if (this.periodSelected.traducciones.it) {
							this.periodSelected.name = this.periodSelected.traducciones.it.name;
							this.periodSelected.headerContent = this.periodSelected.traducciones.it.header_content;
						}
						break;
					case "en":
						if (this.periodSelected.traducciones.en) {
							this.periodSelected.name = this.periodSelected.traducciones.en.name;
							this.periodSelected.headerContent = this.periodSelected.traducciones.en.header_content;
						}
						break;
					case "fr":
						if (this.periodSelected.traducciones.fr) {
							this.periodSelected.name = this.periodSelected.traducciones.fr.name;
							this.periodSelected.headerContent = this.periodSelected.traducciones.fr.header_content;
						}
						break;
					case "pt":
						if (this.periodSelected.traducciones.pt) {
							this.periodSelected.name = this.periodSelected.traducciones.pt.name;
							this.periodSelected.headerContent = this.periodSelected.traducciones.pt.header_content;
						}
						break;

					default:
						break;
				}

				// Evaluation form
				this.evaluationSvc.getEvaluationFormByIdPOLL(this.params.formId).subscribe((data) => {
					this.evaluationForm = data;
					console.log("Evaluation form", data);
					this.getRequiredId();
					this.selectedBlock = this.evaluationForm.blocks[0];
					// Load evaluation
					this.loadPoll().then(() => {
						console.log("Evaluation", data);
						// Set results
						//   this.selectedBlock.sections.forEach(section => {
						// 	  section.elements.forEach(element => {
						// 		  element.result = this.findElementResult(element.id);
						// 	  })
						//   });
					});
				});
			});
			this.userSvc.getUser().subscribe((data) => {
				this.user = data;
			});
		});
	}
	getRequiredId() {
		const bloques = this.evaluationForm.blocks;

		for (let bloque of bloques) {
			for (let section of bloque.sections) {
				for (let element of section.elements) {
					if (element.required == 1) this.sectionsRequired.push(element.id);
				}
			}
		}

		console.log("Required", this.sectionsRequired);
	}

	loadPoll() {
		return new Promise((resolve, reject) => {
			this.evaluationSvc.getEvaluation(this.params.formId, this.user.id, this.periodSelected.id, "0").subscribe((data: Evaluation) => {
				//Si no existe, crear una nueva evaluación
				if (!data) {
					this.evaluation = {
						id: null,
						personId: this.user.id,
						evaluationFormId: this.params.formId,
						periodId: this.periodSelected.id,
						creationDate: null,
						updateDate: null,
						iterationNumber: 0,
						totalResult: 0,
						partial: null,
					};
				} else {
					this.evaluation = data;
				}
				// Obtenemos la evaluacion parcial del evaluador
				this.evaluationSvc.getEvaluationPartial(this.user.id, this.user.id, this.periodSelected.id).subscribe((data: Partial) => {
					// Si no exite, crear un nuevo parcial
					if (!data) {
						this.evaluation.partial = {
							id: null,
							comment: null,
							creationDate: null,
							updateDate: null,
							evaluationId: null,
							evaluatorId: this.user.id,
							status: "DRAFT",
							results: [],
						};
					} else {
						this.evaluation.partial = data;
					}
					// Filtrar los resultados que no sean de eleentos
					console.log("Evaluacion", this.evaluation);
					// Edit Mode
					const _isActive = moment().isBetween(this.periodSelected.startPeriod, this.periodSelected.endPeriod) ? true : false;
					this.editMode = this.evaluation.partial.status !== "DONE" && _isActive ? true : false;
					resolve(null);
				});
			});
		});
	}

	findElementResult(elementId) {
		const _emptyResult = { result: null, comment: null };
		if (this.evaluation && this.evaluation.partial) {
			const elementResult = this.evaluation.partial.results.find((r) => (r.elementId || r.actionId || r.sectionId || r.blockId) === elementId); //FIXME Controlar los tipos de elementos
			return elementResult ? elementResult : _emptyResult;
		}
	}

	onChangeElementValue(evt) {
		const _selectedElementResult = this.findElementResult(evt.id);
		if (_selectedElementResult.result !== null) {
			_selectedElementResult.result = evt.value;
		} else {
			const _elementResult = {
				id: null,
				[evt.target]: evt.id,
				evaluationPartialId: this.evaluation.partial.id,
				result: evt.value,
				type: evt.type,
			};
			this.evaluation.partial.results.push(_elementResult);
		}
	}

	onChangeComment(evt) {
		const _selectedElementResult = this.findElementResult(evt.id);
		if (_selectedElementResult.comment !== null) {
			_selectedElementResult.comment = evt.comment;
		} else {
			const _elementResult = {
				id: null,
				[evt.target]: evt.id,
				evaluationPartialId: this.evaluation.partial.id,
				result: null,
				comment: evt.comment,
				type: evt.type,
			};
			this.evaluation.partial.results.push(_elementResult);
		}
	}

	openDialog() {
		//Comprobamos primero los required
		const resultados = this.mapPoll()?.partial?.results;
		console.log("RESULTADOS", resultados);
		const resultadosFiltrados = resultados.filter((resultado) => this.sectionsRequired.includes(resultado.elementId));
		if (this.sectionsRequired.length && resultadosFiltrados.length >= this.sectionsRequired.length) {
			const dialogRef = this.dialog.open(SendPollComponent, {
				panelClass: [],
				width: "50vw",
				disableClose: false,
				autoFocus: false,
				data: {
					confirm: this.sendPoll.bind(this),
					close: () => dialogRef.close(),
				},
			});
		} else {
			this.snackBar.open("No se ha podido enviar la encuesta. Faltan elementos requeridos", "X", {
				duration: 3000,
				verticalPosition: "bottom",
			});
		}
	}

	sendPoll() {
		let _body = this.mapPoll();
		_body.partial.status = "DONE";
		console.log("SAVE", _body);
		this.dialog.closeAll();
		this.evaluationSvc.savePoll(_body).subscribe((data) => {
			if (data.response === "OK") {
				this.dialog.closeAll();
				this.loadPoll();
				//Enviar email
			}
		});
	}

	mapPoll() {
		//Solo se envian los elementos con resultados
		let _partial = Object.assign({}, this.evaluation.partial);
		_partial.status = "DRAFT";
		//Se añade el parcial a guardar en la evaluacion
		let _evaluation = Object.assign({}, this.evaluation);
		_evaluation.partial = _partial;
		return _evaluation;
	}

	transform(html) {
		return this.sanitizer.bypassSecurityTrustHtml(html);
	}

	tooglePanel(section: any) {
		section.extended = !section.extended;
	}
}
