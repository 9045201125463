import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSnackBarModule } from "@angular/material/snack-bar";

import { FuseSharedModule } from "@fuse/shared.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ImageCropperModule } from "ngx-image-cropper";

import { PollComponent } from "./poll.component";
import { SharedComponentsModule } from "../../components/shared-components.module";
import { FormComponent } from "./form/form.component";
import { PollInputComponent } from "./poll-input/poll-input.component";
import { MatSelectModule } from "@angular/material/select";
import { SendPollComponent } from "./send-poll-dialog/send-poll-dialog.component";
import { ItemStarsPollComponent } from "./item-stars-poll/item-stars.component";

const routes = [
	{
		path: "poll",
		component: PollComponent,
	},
	{
		path: "poll/form",
		component: FormComponent,
	},
];

@NgModule({
	declarations: [PollComponent, FormComponent, PollInputComponent, SendPollComponent, ItemStarsPollComponent],
	imports: [
		RouterModule.forChild(routes),

		TranslateModule,
		MatButtonModule,
		MatIconModule,
		MatInputModule,
		MatFormFieldModule,
		MatSelectModule,
		MatTabsModule,
		MatTooltipModule,
		MatSnackBarModule,

		FuseSharedModule,
		SharedComponentsModule,
		FontAwesomeModule,
		InfiniteScrollModule,
		ImageCropperModule,
	],
})
export class PollModule {}
