import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
	selector: "send-poll-dialog",
	templateUrl: "send-poll-dialog.component.html",
	styleUrls: ["send-poll-dialog.component.scss"],
})
export class SendPollComponent {
	constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

	confirm() {
		this.data.confirm();
	}

	close() {
		this.data.close();
	}
}
