import { Component, Input, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, Output, EventEmitter } from "@angular/core";
import { ProjectService } from "app/services/project/project.service";

@Component({
	selector: "item-stars-poll",
	templateUrl: "./item-stars.component.html",
	styleUrls: ["./item-stars.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemStarsPollComponent implements OnInit {
	@Input() stars: Array<String>;
	@Input() view: string;
	@Input() editable: boolean;

	@Output() onStarsChange = new EventEmitter();

	currentStars: Array<String>;
	project_name = "";
	constructor(
		private changer: ChangeDetectorRef,
		private projectSvc: ProjectService,
	) {
		this.editable = false;
	}

	ngOnInit(): void {
		this.project_name = this.projectSvc.projectConfig.name;
	}

	ngAfterViewChecked(): void {
		this.editable = this.view === "me" && this.editable ? true : false;
		this.currentStars = this.stars;
		this.changer.detectChanges();
	}

	setStar(id) {
		this.currentStars = this.printStars(id);
		this.onStarsChange.emit(this.currentStars);

		console.log(this.currentStars);
	}

	printStars(index) {
		let _array = new Array(5);
		for (let i = 0; i < 5; i++) {
			if (i < index + 1) {
				_array[i] = "X";
			} else {
				_array[i] = "-";
			}
		}
		return _array;
	}

	resetStars() {
		this.currentStars = this.stars;
		this.changer.detectChanges();
	}

	countVal(): number {
		return this.currentStars ? this.currentStars.filter((item) => item === "X").length : 0;
	}
}
