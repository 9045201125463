@if(permisos.r){
<div id="poll" class="page-layout simple fullwidth p-24">
	<div @onRenderUp fxLayout="column">
		<!-- Activas -->
		<h1>{{ "poll.encuestas_activas" | translate }}</h1>
		<div fxLayout="row wrap" fxLayoutAlign="space-evenly">
			<div *ngIf="activePolls && !activePolls.length" class="empty-poll-container p-24 fullwidth">
				<h3 class="m-0">{{ "poll.no_encontradas" | translate }}</h3>
			</div>
			<div
				*ngFor="let poll of activePolls"
				fxLayout="row wrap"
				fxLayoutAlign="center center"
				class="fuse-card card-border-top card-fullwidth poll-card p-24 m-24"
			>
				<h1 style="font-size: 200%" class="tituloAzul">{{ poll.name }}</h1>
				<!--
                    <div *ngIf="poll.headerContent" [innerHTML]="poll.headerContent"></div>
                -->

				<div *ngIf="poll.headerImg" style="margin-bottom: 15px">
					<img [src]="poll.headerImg" alt="Descripción de la imagen" />
				</div>

				<div fxLayoutAlign="left left" class="fullwidth" fxLayoutAlign="center center">
					<div fxFlex="70" fxLayout="column" class="mt-12 time-container">
						<div>
							<span>{{ "poll.inicio" | translate }}: {{ formDate(poll.startPeriod) }}</span>
							<span class="ml-4">{{ "poll.fin" | translate }}: {{ formDate(poll.endPeriod) }}</span>
						</div>
						<div fxLayoutAlign="start center">
							<mat-icon>schedule</mat-icon>
							<span>{{ "poll.restante" | translate }}: {{ poll.counterTimer }}</span>
						</div>
					</div>
					<div fxFlex="30" fxLayoutAlign="center center">
						<button *ngIf="poll.formId && poll.status === 'DONE'" class="action-button" (click)="openPoll(poll)">
							{{ "poll.respuesta" | translate }}
						</button>
						<button *ngIf="poll.formId && poll.status !== 'DONE'" class="action-button" (click)="openPoll(poll)">
							{{ "poll.comenzar" | translate }}
						</button>
						<button *ngIf="!poll.formId" class="action-buttonBad" [disabled]="true" (click)="openPoll(poll)">
							{{ "poll.no_disponible" | translate }}
						</button>
					</div>
				</div>
			</div>
		</div>

		<!-- Histórico -->
		<h1>{{ "poll.historico" | translate }}</h1>
		<div *ngIf="!pastPolls?.length" class="empty-poll-container fullwidth">
			<h3 class="m-0">{{ "poll.no_encontradas_past" | translate }}</h3>
		</div>
		<div *ngIf="pastPolls?.length" @slideUpList fxLayout="row wrap" fxLayoutAlign="space-evenly">
			<div
				*ngFor="let poll of pastPolls"
				fxLayout="row wrap"
				fxLayoutAlign="center center"
				class="fuse-card card-border-top card-fullwidth poll-card p-24 m-24"
			>
				<div fxFlex="70">
					<span class="font-size-18">{{ poll.name }}:</span><span class="font-size-14 ml-4">{{ poll.formName }}</span>
				</div>
				<div fxFlex="30" fxLayoutAlign="center center">
					<button *ngIf="poll.formId" class="action-button" (click)="openPoll(poll)">{{ "poll.visualizar" | translate }}</button>
					<button *ngIf="!poll.formId" [disabled]="true">{{ "poll.no_disponible" | translate }}</button>
				</div>
			</div>
		</div>
	</div>
</div>
}
