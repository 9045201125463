<!-- SELECT -->
<div fxLayout="row" *ngIf="element.type === 'SELECT'" style="width: 100%">
	<div *ngIf="element.type === 'SELECT'" fxLayout="column" fxLayoutAlign="flex-end start" class="panel-status" fxFlex="80">
		<h1 fxFlex="100" class="m-0 elementHead">{{ element.name }} <b *ngIf="element.required === 1" style="color: rgb(255, 50, 50)"> *</b></h1>
	</div>
	<div *ngIf="element.type === 'SELECT'" fxLayout="column" fxLayoutAlign="flex-end end" class="panel-status" style="margin-right: 5%" fxFlex="20">
		<!-- ReadOnly -->
		<div *ngIf="readOnly && userResult.result" fxLayout="row" style="width: 100%; margin: 0px !important; padding: 0px !important">
			<mat-select class="form-control disabled" [(value)]="userResult.result" disabled placeholder="Seleccione una opción...">
				<mat-option *ngFor="let option of selectOptions" [value]="option.value">{{ option.name }}</mat-option>
				<mat-option *ngIf="element.noApplyOption" value="NA">No aplica</mat-option>
			</mat-select>
		</div>
		<div *ngIf="readOnly && !userResult.result" fxLayout="row" fxLayoutAlign="center center" style="width: 100%">
			<span>Sin respuesta</span>
		</div>
		<!-- WriteMode -->
		<div *ngIf="!readOnly" fxLayout="row" style="width: 100%; margin: 0px !important; padding: 0px !important">
			<mat-select
				class="form-control"
				#filterSelect
				[(value)]="userResult.result"
				(selectionChange)="setResult(filterSelect.value)"
				placeholder="Seleccione una opción..."
			>
				<mat-option *ngFor="let option of selectOptions" [value]="option.value">{{ option.name }}</mat-option>
				<mat-option *ngIf="element.noApplyOption" value="NA">No aplica</mat-option>
			</mat-select>
		</div>
	</div>
</div>

<div fxLayout="row" fxLayoutAlign="start start" *ngIf="element.description && element.type === 'SELECT'" style="margin-right: 5%">
	<div fxFlex="100" class="m-0" [innerHtml]="element.description"></div>
</div>

<!-- TEXTAREA -->
<div fxLayout="row" fxLayoutAlign="start start" *ngIf="element.description && element.type === 'TEXTAREA'" style="margin-right: 5%">
	<div fxFlex="100" class="m-0" [innerHtml]="element.description"></div>
</div>
<div *ngIf="element.type === 'TEXTAREA'">
	<!-- Read only -->
	<div *ngIf="readOnly && userResult.comment">
		<!-- User -->
		<div *ngIf="userResult.comment" fxLayout="row" fxLayoutAlign="center center" class="comment-container" style="margin-left: 5%">
			<div class="m-0 comment" [innerHtml]="userResult.comment"></div>
		</div>
	</div>
	<div *ngIf="readOnly && !userResult.comment" fxLayout="row" fxLayoutAlign="center center" style="margin-left: 5%">
		<span>Sin respuesta</span>
	</div>
	<!-- Write mode -->
	<div *ngIf="!readOnly" class="mt-16 fullwidth" fxLayoutAlign="center center">
		<textarea class="form-control" [(ngModel)]="userResult.comment" (keyup)="setResult($event)" placeholder="Escribe tu respuesta aquí"></textarea>
	</div>
</div>

<!--:::| TRAFFIC LIGHT |:::-->
<div fxLayout="row" *ngIf="element.type === 'TRAFFIC_LIGHT'" style="width: 100%">
	<div *ngIf="element.type === 'TRAFFIC_LIGHT'" fxLayout="column" fxLayoutAlign="flex-end start" class="panel-status" fxFlex="80">
		<h1 fxFlex="100" class="m-0 elementHead">{{ element.name }}<b *ngIf="element.required == 1" style="color: rgb(255, 50, 50)"> *</b></h1>
	</div>
	<div
		*ngIf="element.type === 'TRAFFIC_LIGHT'"
		fxLayout="column"
		fxLayoutAlign="flex-end end"
		class="panel-status"
		style="margin-right: 5%"
		fxFlex="20"
	>
		<!-- ReadOnly -->
		<div *ngIf="readOnly && userResult?.result" fxLayout="row" fxLayoutAlign="flex-end center" style="opacity: 0.8">
			<button
				mat-mini-fab
				class="ml-4 btn-success"
				[class]="userResult?.result !== '100' ? 'no-select' : ''"
				matTooltip="Completado"
				matTooltipPosition="above"
			>
				<mat-icon>check</mat-icon>
			</button>
			<button
				mat-mini-fab
				class="ml-4 btn-warning"
				[class]="userResult?.result !== '50' ? 'no-select' : ''"
				matTooltip="En progreso"
				matTooltipPosition="above"
			>
				<mat-icon>access_time</mat-icon>
			</button>
			<button
				mat-mini-fab
				class="ml-4 btn-danger"
				[class]="userResult?.result !== '0' ? 'no-select' : ''"
				matTooltip="No completado"
				matTooltipPosition="above"
			>
				<mat-icon>close</mat-icon>
			</button>
			<button
				*ngIf="element.noApplyOption"
				mat-mini-fab
				class="ml-4 btn-muted"
				[class]="userResult?.result !== 'NA' ? 'no-select' : ''"
				matTooltip="No aplica"
				matTooltipPosition="above"
			>
				<mat-icon>pause</mat-icon>
			</button>
		</div>
		<!-- WriteMode -->
		<div *ngIf="!readOnly" fxLayout="row" class="botonera-planes-accion">
			<button
				mat-mini-fab
				class="ml-4 btn-success"
				[class]="userResult?.result !== '100' ? 'no-select' : ''"
				matTooltip="Completado"
				matTooltipPosition="above"
				(click)="setResult('100')"
			>
				<mat-icon>check</mat-icon>
			</button>
			<button
				mat-mini-fab
				class="ml-4 btn-warning"
				[class]="userResult?.result !== '50' ? 'no-select' : ''"
				matTooltip="En progreso"
				matTooltipPosition="above"
				(click)="setResult('50')"
			>
				<mat-icon>access_time</mat-icon>
			</button>
			<button
				mat-mini-fab
				class="ml-4 btn-danger"
				[class]="userResult?.result !== '0' ? 'no-select' : ''"
				matTooltip="No completado"
				matTooltipPosition="above"
				(click)="setResult('0')"
			>
				<mat-icon>close</mat-icon>
			</button>
			<button
				*ngIf="element.noApplyOption"
				mat-mini-fab
				class="ml-4 btn-muted"
				[class]="userResult?.result !== 'NA' ? 'no-select' : ''"
				matTooltip="No aplica"
				matTooltipPosition="above"
				(click)="setResult('NA')"
			>
				<mat-icon>pause</mat-icon>
			</button>
		</div>
	</div>
</div>
<div fxLayout="row" fxLayoutAlign="start start" style="margin-right: 5%" *ngIf="element.description && element.type === 'TRAFFIC_LIGHT'">
	<div fxFlex="100" class="m-0" [innerHtml]="element.description"></div>
</div>

<!--RATING STARS-->
<div fxLayout="row" *ngIf="element.type === 'RATING_STARS'" style="width: 100%">
	<div *ngIf="element.type === 'RATING_STARS'" fxLayout="column" fxLayoutAlign="flex-end start" class="panel-status" fxFlex="80">
		<h1 fxFlex="100" class="m-0 elementHead">{{ element.name }}<b *ngIf="element.required == 1" style="color: rgb(255, 50, 50)"> *</b></h1>
	</div>
	<div
		*ngIf="element.type === 'RATING_STARS'"
		fxLayout="column"
		fxLayoutAlign="flex-end end"
		class="panel-status"
		style="margin-right: 5%"
		fxFlex="20"
	>
		<item-stars-poll
			[stars]="printStars(userResult?.result)"
			[editable]="!readOnly"
			[view]="'me'"
			(onStarsChange)="onChangeStarsValue($event, element)"
		>
		</item-stars-poll>
	</div>
</div>
<div fxLayout="row" fxLayoutAlign="start start" *ngIf="element.description && element.type === 'RATING_STARS'" style="margin-right: 5%">
	<div fxFlex="100" class="m-0" [innerHtml]="element.description"></div>
</div>

<!-- Element comment -->
<div *ngIf="element.allowComment === 1 && element.type !== 'TEXTAREA'">
	<!-- Read only -->
	<div *ngIf="readOnly && userResult.comment" style="margin-left: 5%">
		<!-- User -->
		<div *ngIf="userResult.comment" fxLayout="row" fxLayoutAlign="center center" class="comment-container">
			<div class="m-0 comment" [innerHtml]="userResult.comment"></div>
		</div>
	</div>
	<!-- Write mode -->
	<div *ngIf="!readOnly" class="mt-16 fullwidth" fxLayoutAlign="center center">
		<textarea class="form-control" [(ngModel)]="userResult.comment" placeholder="¿Desea incluir un comentario?"></textarea>
	</div>
</div>
