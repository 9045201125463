import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { EvaluationService } from "app/services/evaluation/evaluation.service";

@Component({
	selector: "poll-input",
	templateUrl: "./poll-input.component.html",
	styleUrls: ["./poll-input.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class PollInputComponent implements OnInit {
	@Input("element") element: any;
	@Input("userResult") userResult: any;
	@Input("readOnly") readOnly: boolean;

	@Output() onChangeValue = new EventEmitter();
	@Output() onChangeComment = new EventEmitter();

	selectOptions: Array<any> = [];

	constructor(private evaluationSvc: EvaluationService) {}

	ngOnInit(): void {
		// Init default
		if (!this.userResult) {
			this.userResult = {
				result: null,
				comment: null,
			};
		}
		// Se cargan los options de los metadatos.
		if (this.element.metadata) {
			this.loadSelectOptions();
		}
	}

	loadSelectOptions() {
		const options = this.element.metadata ? JSON.parse(this.element.metadata) : {};
		Object.keys(options).forEach((o) => {
			this.selectOptions.push({
				name: o,
				value: options[o],
			});
		});
	}

	setResult(result) {
		if (this.element.type === "TEXTAREA") {
			const _commment = result.target.value;
			this.userResult.comment = _commment;
			// FIXME Al ser object y estar referenciado, podemos omitir el emitter y setearlo desde aquí
			this.onChangeComment.emit({
				id: this.element.id,
				target: "elementId",
				type: this.element.type,
				value: null,
				comment: _commment,
			});
		} else {
			this.userResult.result = result;
			// FIXME Al ser object y estar referenciado, podemos omitir el emitter y setearlo desde aquí
			this.onChangeValue.emit({
				id: this.element.id,
				target: "elementId",
				type: this.element.type,
				value: this.userResult.result,
			});
		}
	}

	printStars(result) {
		let _index;
		if (!result) {
			_index = 0;
		} else {
			_index = Math.floor(result / 16.66);
		}

		let _array = new Array(5);
		for (let i = 0; i < 5; i++) {
			if (i < _index) {
				_array[i] = "X";
			} else {
				_array[i] = "-";
			}
		}
		return _array;
	}

	onChangeStarsValue(evt, elm) {
		if (this.userResult.result != null) {
			this.userResult.result = this.calculateElementResult(evt);
		} else {
			const _newResult = {
				id: null,
				blockId: null,
				sectionId: null,
				elementId: elm.id,
				comment: null,
				result: this.calculateElementResult(evt),
			};
			this.userResult = _newResult;
			//}
		}
		this.onChangeValue.emit({
			id: this.element.id,
			target: "elementId",
			type: this.element.type,
			value: this.userResult.result,
		});
	}

	calculateElementResult(evt) {
		const result = evt.filter((r) => r === "X");
		return result.length * 20;
	}
}
