<div @onRenderUp id="poll-form" class="page-layout simple fullwidth p-24">
	<!-- Header -->
	<div *ngIf="periodSelected" class="p-24">
		<h1 style="font-size: 300%" class="tituloAzul">{{ periodSelected.name }}</h1>
	</div>
	<div
		fxLayout="row"
		style="width: 80%; margin-left: 10%"
		fxLayoutAlign="space-between center"
		class="pt-24 pl-24 pr-24"
		*ngIf="periodSelected?.headerContent && projectId != 94"
	>
		<div [innerHTML]="periodSelected?.headerContent"></div>
	</div>
	<div fxLayout="row" fxLayoutAlign="center center" class="pt-24 pl-24 pr-24" *ngIf="periodSelected?.headerImg && projectId == 94">
		<img style="width: 50%" [src]="periodSelected.headerImg" alt="Descripción de la imagen" />
	</div>

	<div fxLayout="row" style="width: 80%; margin-left: 10%" fxLayoutAlign="space-between center" class="pt-24 pl-24 pr-24">
		<h3 class="m-0 font-size-20 text-theme">{{ "poll.responde" | translate }}</h3>
	</div>

	<div fxLayout="row" style="width: 80%; margin-left: 10%" class="eval-form">
		<!-- EVALUATION -->
		<div *ngIf="evaluationForm" fxFlex="100" class="px-16 py-24">
			<!--||| General |||-->
			<div>
				<div>
					<div *ngFor="let section of selectedBlock.sections" class="panel panel-card panel-theme">
						<div fxLayout="row" fxLayoutAlign="space-between left" class="panel-heading" style="cursor: pointer" (click)="tooglePanel(section)">
							<h3 class="panel-title">
								<b style="font-size: 150%">{{ section.name }}</b>
							</h3>
							<mat-icon class="s-18" style="font-size: 150%">{{ !section.extended ? "keyboard_arrow_down" : "keyboard_arrow_right" }}</mat-icon>
						</div>

						<div *ngIf="!section.extended" class="panel-body">
							<div>
								<div fxLayoutAlign="space-between center" *ngIf="periodSelected?.headerContent && projectId == 94">
									<div [innerHTML]="periodSelected?.headerContent"></div>
								</div>
								<div *ngFor="let element of section.elements" class="panel panel-theme" [class]="'panel-' + element.estado">
									<div fxLayout="row" fxLayoutAlign="start start" class="panel-body" style="width: 100%" *ngIf="element.type != 'TEXTAREA'">
										<poll-input
											*ngIf="evaluation"
											class="fullwidth"
											[element]="element"
											[userResult]="findElementResult(element.id)"
											[readOnly]="editMode ? false : true"
											(onChangeValue)="onChangeElementValue($event)"
											(onChangeComment)="onChangeComment($event)"
										>
										</poll-input>
									</div>
									<div fxLayout="row" fxLayoutAlign="start start" class="p-8">
										<h1 *ngIf="element.name && element.type == 'TEXTAREA'" fxFlex="100" class="m-0 elementHead">
											{{ element.name }}<b *ngIf="element.required == 1" style="color: rgb(255, 50, 50)"> *</b>
										</h1>
									</div>

									<div fxLayout="row" fxLayoutAlign="start start" class="panel-body" style="width: 100%" *ngIf="element.type == 'TEXTAREA'">
										<poll-input
											*ngIf="evaluation"
											class="fullwidth"
											[element]="element"
											[userResult]="findElementResult(element.id)"
											[readOnly]="editMode ? false : true"
											(onChangeValue)="onChangeElementValue($event)"
											(onChangeComment)="onChangeComment($event)"
										>
										</poll-input>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="editMode">
				<h1></h1>
				<button *ngIf="editMode" class="action-button" (click)="openDialog()">
					<mat-icon class="mr-8">send</mat-icon>
					{{ "poll.send" | translate }}
				</button>
			</div>
		</div>
	</div>
</div>
