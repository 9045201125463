import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { TranslateService } from "@ngx-translate/core";
import { feedAnimations } from "app/animations";
import { AuthService } from "app/services/auth/auth.service";
import { EvaluationService } from "app/services/evaluation/evaluation.service";
import { ProjectService } from "app/services/project/project.service";
import { UserService } from "app/services/user/user.service";
import { User } from "app/shared/models/user.interface";
import { Partial } from "app/shared/models/vuelta-d.interface";

import { environment } from "environments/environment";
import moment from "moment";

@Component({
	selector: "app-poll",
	templateUrl: "./poll.component.html",
	styleUrls: ["./poll.component.scss"],
	animations: [feedAnimations],
})
export class PollComponent implements OnInit {
	user: User;
	activePolls: any;
	pastPolls: any;

	lang = "es";
	constructor(
		private router: Router,
		private sanitizer: DomSanitizer,
		private changer: ChangeDetectorRef,
		private userSvc: UserService,
		private evaluationSvc: EvaluationService,
		private projectSvc: ProjectService,
		private fuseMenuSvc: FuseNavigationService,
		private translate: TranslateService,
		private authSvc: AuthService
	) {}
	permisos = { r: 0, w: 0, d: 0 };

	ngOnInit(): void {
		// Check page access
		this.authSvc.getPermisosWeb("WEB-POLL").subscribe((data) => {
			console.log("Permisos para esta pagina", data);
			if (data) {
				this.permisos.r = data.r;
				this.permisos.w = data.w;
				this.permisos.d = data.d;
			}
		});
		this.fuseMenuSvc.updateNavigationItem("poll", { badge: null });

		this.lang = localStorage.getItem("currentLang");

		this.projectSvc.getProjectConfig(false).subscribe((project) => {
			if (!project.enabled_mod_polls) {
				this.router.navigate(["/inicio"]);
			}
		});
		this.userSvc.getUser().subscribe((data) => {
			this.user = data;
			// Active polls
			this.evaluationSvc.getActivePolls().subscribe((data) => {
				console.log("activePolls", data);
				this.activePolls = data;
				// Get active polls status
				this.activePolls.forEach((poll) => this.getPollStatus(poll));
				this.getTimer();
				this.changer.detectChanges();
			});
		});
		this.evaluationSvc.getPastPolls().subscribe((data) => {
			console.log("pastPolls", data);
			this.pastPolls = data;
			this.changer.detectChanges();
		});
	}

	getPollStatus(poll) {
		//Imagen
		poll.headerImg = environment.urlDomain + "/rest/image/" + poll.headerImg;
		this.evaluationSvc.getEvaluationPartial(this.user.id, this.user.id, poll.id).subscribe((data: Partial) => {
			poll.status = data.status;
			this.changer.detectChanges();
		});
		//Traduccimos
		console.log("che confusione sarà perche ti amo", this.lang);

		switch (this.lang) {
			case "it":
				if (poll.traducciones.it) {
					poll.name = poll.traducciones.it.name;
					poll.header_content = poll.traducciones.it.header_content;
				}
				break;
			case "en":
				if (poll.traducciones.en) {
					poll.name = poll.traducciones.en.name;
					poll.header_content = poll.traducciones.en.header_content;
				}
				break;
			case "fr":
				if (poll.traducciones.fr) {
					poll.name = poll.traducciones.fr.name;
					poll.header_content = poll.traducciones.fr.header_content;
				}
				break;
			case "pt":
				if (poll.traducciones.pt) {
					poll.name = poll.traducciones.pt.name;
					poll.header_content = poll.traducciones.pt.header_content;
				}
				break;

			default:
				break;
		}
	}

	transform(html) {
		return this.sanitizer.bypassSecurityTrustHtml(html);
	}

	formDate(date) {
		return moment(date).format("DD/MM/YYYY HH:mm");
	}

	initCounterTimer() {
		setInterval(this.getTimer.bind(this), 1000); //FIXME
	}

	getTimer() {
		this.activePolls.forEach((poll) => {
			const _end = moment(poll.endPeriod);
			const _now = moment();
			const days = _end.diff(_now, "days");
			const hours = _end.diff(_now, "hours") - days * 24;
			const minutes = _end.diff(_now, "minutes") - days * 24 * 60 - hours * 60;
			const seconds = _end.diff(_now, "seconds") - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
			poll.counterTimer =
				days +
				" días " +
				(hours >= 9 ? hours : "0" + hours) +
				":" +
				(minutes >= 9 ? minutes : "0" + minutes) +
				":" +
				(seconds >= 9 ? seconds : "0" + seconds);
		});
	}

	openPoll(poll) {
		const _params = {
			id: poll.id,
			formId: poll.formId,
		};
		this.router.navigate(["poll/form"], { queryParams: _params });
	}
}
