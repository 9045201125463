<!-- Editable -->
<ng-container *ngIf="project_name == 'syrsa'">
	<div fxLayout="row" id="item-stars" *ngIf="editable">
		<div [ngClass]="{ me: view === 'me', pointer: editable === true }" [id]="i" *ngFor="let star of currentStars; let i = index" (click)="setStar(i)">
			<mat-icon class="star1" *ngIf="star == 'X' && i == 0">star</mat-icon>
			<mat-icon class="star2" *ngIf="star == 'X' && i == 1">star</mat-icon>
			<mat-icon class="star3" *ngIf="star == 'X' && i == 2">star</mat-icon>
			<mat-icon class="star4" *ngIf="star == 'X' && i == 3">star</mat-icon>
			<mat-icon class="star5" *ngIf="star == 'X' && i == 4">star</mat-icon>
			<mat-icon class="gray" *ngIf="star == '-'">star</mat-icon>
		</div>
	</div>
	<div fxLayout="row" fxLayoutAlign="center center" *ngIf="editable">
		<label *ngIf="countVal() == 0"></label>
		<span *ngIf="countVal() == 1" style="color: rgb(255, 0, 0)">Bajo</span>
		<label *ngIf="countVal() == 2" style="color: rgb(255, 154, 2)">Medio-Bajo</label>
		<label *ngIf="countVal() == 3" style="color: rgb(255, 192, 2)">Medio</label>
		<label *ngIf="countVal() == 4" style="color: rgb(146, 208, 80)">Alto</label>
		<label *ngIf="countVal() == 5" style="color: rgb(0, 176, 80)">Muy Alto</label>
	</div>

	<div fxLayout="row" id="item-stars" *ngIf="!editable">
		<div [ngClass]="{ me: view === 'me', pointer: editable === true }" [id]="i" *ngFor="let star of currentStars; let i = index">
			<mat-icon class="star1" *ngIf="star == 'X' && i == 0">star</mat-icon>
			<mat-icon class="star2" *ngIf="star == 'X' && i == 1">star</mat-icon>
			<mat-icon class="star3" *ngIf="star == 'X' && i == 2">star</mat-icon>
			<mat-icon class="star4" *ngIf="star == 'X' && i == 3">star</mat-icon>
			<mat-icon class="star5" *ngIf="star == 'X' && i == 4">star</mat-icon>

			<mat-icon class="gray" *ngIf="star == '-'">star</mat-icon>
		</div>
	</div>
	<div fxLayout="row" fxLayoutAlign="center center" *ngIf="!editable">
		<label *ngIf="countVal() == 0"></label>
		<span *ngIf="countVal() == 1" style="color: rgb(255, 0, 0)">Bajo</span>
		<label *ngIf="countVal() == 2" style="color: rgb(255, 154, 2)">Medio-Bajo</label>
		<label *ngIf="countVal() == 3" style="color: rgb(255, 192, 2)">Medio</label>
		<label *ngIf="countVal() == 4" style="color: rgb(146, 208, 80)">Alto</label>
		<label *ngIf="countVal() == 5" style="color: rgb(0, 176, 80)">Muy Alto</label>
	</div>
</ng-container>
<ng-container *ngIf="project_name == 'bluespace'">
	<div fxLayout="row" id="item-stars" *ngIf="editable">
		<div [ngClass]="{ me: view === 'me', pointer: editable === true }" [id]="i" *ngFor="let star of currentStars; let i = index" (click)="setStar(i)">
			<mat-icon class="star1Blue" *ngIf="star == 'X' && i == 0">clear</mat-icon>
			<mat-icon class="star2Blue" *ngIf="star == 'X' && i == 1">star</mat-icon>
			<mat-icon class="star3Blue" *ngIf="star == 'X' && i == 2">star</mat-icon>
			<mat-icon class="star4Blue" *ngIf="star == 'X' && i == 3">star</mat-icon>
			<mat-icon class="star5Blue" *ngIf="star == 'X' && i == 4">star</mat-icon>
			<mat-icon class="gray" *ngIf="star == '-' && i == 0">clear</mat-icon>
			<mat-icon class="gray" *ngIf="star == '-' && i != 0">star</mat-icon>
		</div>
	</div>

	<div fxLayout="row" id="item-stars" *ngIf="!editable">
		<div [ngClass]="{ me: view === 'me', pointer: editable === true }" [id]="i" *ngFor="let star of currentStars; let i = index">
			<mat-icon class="star1Blue" *ngIf="star == 'X' && i == 0">clear</mat-icon>
			<mat-icon class="star2Blue" *ngIf="star == 'X' && i == 1">star</mat-icon>
			<mat-icon class="star3Blue" *ngIf="star == 'X' && i == 2">star</mat-icon>
			<mat-icon class="star4Blue" *ngIf="star == 'X' && i == 3">star</mat-icon>
			<mat-icon class="star5Blue" *ngIf="star == 'X' && i == 4">star</mat-icon>
			<mat-icon class="gray" *ngIf="star == '-' && i == 0">clear</mat-icon>
			<mat-icon class="gray" *ngIf="star == '-' && i != 0">star</mat-icon>
		</div>
	</div>
</ng-container>
<ng-container *ngIf="project_name != 'syrsa' && project_name != 'bluespace'">
	<div id="item-stars" *ngIf="editable">
		<div [ngClass]="{ me: view === 'me', pointer: editable === true }" [id]="i" *ngFor="let star of currentStars; let i = index" (click)="setStar(i)">
			<mat-icon class="golden" *ngIf="star == 'X'">star</mat-icon>
			<mat-icon class="gray" *ngIf="star == '-'">star</mat-icon>
		</div>
	</div>
	<div id="item-stars" *ngIf="!editable">
		<div [ngClass]="{ me: view === 'me', pointer: editable === true }" [id]="i" *ngFor="let star of currentStars; let i = index">
			<mat-icon class="golden" *ngIf="star == 'X'">star</mat-icon>

			<mat-icon class="gray" *ngIf="star == '-'">star</mat-icon>
		</div>
	</div>
</ng-container>
